<template>
  <div class="flex flex-col flex-1">
    <div class="flex items-baseline">
      <img src="@/assets/svg/building-icon.svg" alt="" class="h-4 w-auto mr-2 mt-1">
      <h4 class="text-lg font-bold">{{ buildingName }}</h4>
      <Menu v-if="showMenu" as="div" class="ml-2 relative">
        <MenuButton class="bg-gray-100 ml-auto p-0.5 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
          <span class="sr-only">Open options</span>
          <DotsVerticalIcon class="h-4 w-4" />
        </MenuButton>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            <div class="py-1" role="none">
              <MenuItem @click="$emit('manageUsers')" as="button" class='text-gray-700 block px-4 py-2 text-sm text-left hover:bg-gray-100 cursor-pointer w-full' id="menu-item-0">Manage users</MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>
    <h5 class="text-sm text-gray-500">{{ buildingAddress }}</h5>
  </div>
</template>

<script>
import { DotsVerticalIcon } from '@heroicons/vue/solid';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';

export default {
  name: 'DashboardBuildingCard',
  components: {
    DotsVerticalIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
  },
  props: {
    buildingName: {
      type: String
    },
    buildingAddress: {
      type: String
    },
    showMenu: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style>
</style>
