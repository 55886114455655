<template>
<div>
  <loading-overlay v-if="(!selectedBuilding || !spaces)" />
  <div class="flex flex-col m-8">
    <div class="mb-4 flex justify-between items-center flex-wrap print:hidden">
      <button class="flex text-pink-600 hover:text-pink-700 font-bold" @click="$router.push({ name: 'DashboardBuildingSummary' })">
        <ChevronLeftIcon class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5"/>
        All buildings
      </button>
      <button
        class="inline-flex px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
        @click="showAddSpaceModal = true">
        + Add space
      </button>
    </div>
    <div class="mb-4 p-4 px-5 flex flex-col md:flex-row bg-gray-50 rounded-md text-gray-800">
      <dashboard-building-card
        class="flex-auto w-2/5"
        :buildingName="buildingName"
        :buildingAddress="buildingAddress"
        @manageUsers="userManagementModalOpen = true"
      />
      <hr class="my-5 mt-7 md:hidden">
      <dashboard-community-data-card
        class="md:border-l md:pl-5 md:ml-5 flex-auto w-3/5"
        :updatedDate="latestBackgroundInfectionInformation?.date"
        learnMoreUrl="https://help.particleone.com/background-infection/"
        :backgroundInfection="latestBackgroundInfectionInformation?.percent"
      />
    </div>
    <div class="-my-2 sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <loading-overlay v-if="isCloningSpace"/>
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider flex flex-row">
                  Space
                  <loading-spinner v-show="(building.spaces.data && building.spaces.isValidating)" class="ml-2 h-4 w-4 filter grayscale"/>
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Last updated
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Risk rating
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="space in spaces" :key="space.key" class="hover:bg-gray-100 cursor-pointer">
                <td @click="goToDetail(space)">
                  <router-link v-if="createRoute(space) != null" :to="createRoute(space)" class="flex">
                    <div class="flex-1 px-6 py-4 whitespace-nowrap text-sm text-gray-900 underline">{{ space.name }}</div>
                  </router-link>
                  <div class="px-6 py-4 whitespace-nowrap text-sm text-gray-900 underline" v-else>{{ space.name }}</div>
                </td>
                <td @click="goToDetail(space)">
                  <router-link v-if="createRoute(space) != null" :to="createRoute(space)" class="flex">
                    <div class="flex-1 px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ space.lastUpdated }}</div>
                  </router-link>
                  <div v-else class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ space.lastUpdated }}</div>
                </td>
                <td @click="goToDetail(space)">
                  <router-link v-if="createRoute(space) != null" :to="createRoute(space)" class="flex">
                    <div class="flex-1 px-6 py-4 whitespace-nowrap"><dashboard-risk-badge :risk="space.risk" :badgeStyle="'medium'" /></div>
                  </router-link>
                  <div class="px-6 py-4 whitespace-nowrap" v-else><dashboard-risk-badge :risk="space.risk" :badgeStyle="'medium'" /></div>
                </td>
                <td>
                  <Menu as="div" class="mr-2 relative">
                    <MenuButton class="bg-gray-100 ml-auto p-0.5 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500 print:hidden" id="menu-button" aria-expanded="true" aria-haspopup="true">
                      <span class="sr-only">Open options</span>
                      <DotsVerticalIcon class="h-4 w-4" />
                    </MenuButton>
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                        <div class="py-1" role="none">
                          <MenuItem as="button" @click="editSpace(space)" class='disabled:opacity-50 text-gray-700 block px-4 py-2 text-sm text-left hover:bg-gray-100 cursor-pointer disabled:cursor-not-allowed w-full' id="menu-item-0">Edit space</MenuItem>
                          <MenuItem as="button" @click="cloneSpace(space)" class='disabled:opacity-50 text-gray-700 block px-4 py-2 text-sm text-left hover:bg-gray-100 cursor-pointer disabled:cursor-not-allowed w-full' id="menu-item-1">Clone space</MenuItem>
                          <MenuItem as="button" @click="moveSpace(space)" class='disabled:opacity-50 text-gray-700 block px-4 py-2 text-sm text-left hover:bg-gray-100 cursor-pointer disabled:cursor-not-allowed w-full' id="menu-item-2">Move space</MenuItem>
                          <MenuItem as="button" @click="showConfirmDeleteSpace(space)" class='disabled:opacity-50 text-red-700 block px-4 py-2 text-sm text-left hover:bg-gray-100 cursor-pointer disabled:cursor-not-allowed w-full' id="menu-item-3">Delete space</MenuItem>
                        </div>
                      </MenuItems>
                    </transition>
                  </Menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <create-space-form
    :showModal="showAddSpaceModal"
    :isEditing="isEditing"
    @dismiss="dismissAndReload()"
    v-if="showAddSpaceModal"
    />

    <title-message-modal :showModal="spaceToDelete != null">
      <template v-slot:header>
        <h1 class="text-lg font-medium text-gray-900">Delete {{ spaceToDelete?.name ? spaceToDelete?.name : 'space' }}</h1>
      </template>

      <template v-slot:main>
        <p class="text-sm font-normal text-gray-500">Are you sure you want to delete this space? All of your data will be permanently deleted. This cannot be undone.</p>
      </template>

      <template v-slot:footer>
        <button
          class="inline-flex justify-center sm:items-center px-4 py-2 border border-gray-300 shadow-sm sm:text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
          @click="cancelDelete()">
          Cancel
        </button>
        <button v-if="isDeletingSpace" class="inline-flex justify-center sm:items-center px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
          <svg class="-ml-1 mr-2 h-3 w-3 animate-spin" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.5" cx="10" cy="10" r="8" stroke="currentColor" stroke-width="3"/>
            <path d="M10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
          </svg>
          Deleting...
        </button>
        <button
          v-else
          @click="confirmDeleteSpace()"
          class="inline-flex justify-center sm:items-center px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
          Delete Space
        </button>
      </template>
    </title-message-modal>

    <title-message-modal :showModal="spaceToMove != null">
      <template v-slot:header>
        <h1 class="text-lg font-medium text-gray-900">Move {{ spaceToMove?.name ? spaceToMove?.name : 'space' }}</h1>
      </template>    

      <template v-slot:main>
        <p class="text-sm font-normal text-gray-500">Choose a building from the list below to move the space to.</p>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2 pt-3">
          <div class="mt-1 relative rounded-md shadow-sm">
            <select
              autocomplete=""
              tabindex="-1"
              class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              name="building"
              v-model="buildingToMoveSpace"
            >
              <option disabled selected value=undefined>Please select</option>
              <option v-for="building in buildings" :key="building.id" :value="building">{{ building.name }}</option>
            </select>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <button
          class="inline-flex justify-center sm:items-center px-4 py-2 border border-gray-300 shadow-sm sm:text-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
          @click="cancelMove()">
          Cancel
        </button>
        <button v-if="isMovingSpace" class="inline-flex justify-center sm:items-center px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
          <svg class="-ml-1 mr-2 h-3 w-3 animate-spin" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.5" cx="10" cy="10" r="8" stroke="currentColor" stroke-width="3"/>
            <path d="M10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
          </svg>
          Moving...
        </button>
        <button
          v-else
          @click="confirmMoveSpace()"
          :disabled="moveSpaceButtonDisabled"
          class="disabled:opacity-50 inline-flex justify-center sm:items-center px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
          Move Space
        </button>
      </template>
    </title-message-modal>

    <user-management
      :showModal="userManagementModalOpen"
      :name="buildingName ?? ''"
      :buildingId="this.$route.params.buildingId"
      @close="userManagementModalOpen = false"
    />
  </div>
</div>
</template>

<script>
import { ref, computed, reactive, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { mapActions, useStore } from 'vuex';
import { ChevronLeftIcon, DotsVerticalIcon } from '@heroicons/vue/solid';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';

import DashboardBuildingCard from './DashboardBuildingCard.vue';
import DashboardCommunityDataCard from './DashboardCommunityDataCard.vue';
import DashboardRiskBadge from './DashboardRiskBadge.vue';
import CreateSpaceForm from './CreateSpaceForm.vue';
import { missingAnySpaceFields, isNullUndefinedOrEmpty } from '@/models/create-building';
import TitleMessageModal from '@/components/modals/TitleMessageModal.vue';
import UserManagement from '@/components/user-management/UserManagement.vue';
import { DashboardBuilding, DashboardSpace, DashboardBackgroundInfectionInformation } from '@/models/dashboard';
import LoadingOverlay from '@/components/overlays/LoadingOverlay.vue';
import LoadingSpinner from '../spinners/LoadingSpinner.vue';
import buildingApiService from '@/network/services/building';

export default {
  name: 'DashboardSpaceSummary',
  pageTitle() {
    return this.buildingName ?? 'Loading...';
  },
  components: {
    DashboardBuildingCard,
    DashboardCommunityDataCard,
    DashboardRiskBadge,
    ChevronLeftIcon,
    CreateSpaceForm,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsVerticalIcon,
    TitleMessageModal,
    UserManagement,
    LoadingOverlay,
    LoadingSpinner,
  },
  computed: {
    selectedBuilding() {
      if (this.building.data?.building) {
        return new DashboardBuilding(this.building.data.building);
      }
      return null;
    },
    buildingName() {
      return this.selectedBuilding?.name;
    },
    spaces() {
      return this.building.spaces.data?.map(space => new DashboardSpace(space));
    },
    buildingAddress() {
      return this.selectedBuilding?.address;
    },
    latestBackgroundInfectionInformation() {
      if (!this.building.data?.background_infection) {
        return null;
      }
      const formattedInformation = new DashboardBackgroundInfectionInformation(this.building.data.background_infection).formattedInformation;
      return formattedInformation[formattedInformation.length - 1];
    },
    componentKey() {
      return `${Math.random()}`;
    },
    buildings() {
      return (this.allBuildings ?? []).filter(building => building.id !== this.buildingId);
    }
  },
  setup() {
    const showAddSpaceModal = ref(false);
    const isEditing = ref(false);
    const spaceToDelete = ref(null);
    const isDeletingSpace = ref(false);
    const userManagementModalOpen = ref(false);
    const store = useStore();
    const isCloningSpace = ref(false);
    const spaceToMove = ref(null);
    const buildingToMoveSpace = ref(undefined);
    const moveSpaceButtonDisabled = computed(() => isNullUndefinedOrEmpty(buildingToMoveSpace.value));
    const isMovingSpace = ref(false);

    const route = useRoute();
    const buildingId = computed(() => Number(route.params.buildingId) );

    // Construct reactive compound object with building SWRV object and nested spaces SWRV object
    const building = reactive({
      ...buildingApiService.getBuildingDetail(buildingId.value),
      spaces: {...buildingApiService.getAllSpaces(buildingId.value)},
    });
    const allBuildings = buildingApiService.getAllBuildings().data;

    // NOTE: Some components rely on Vuex state, so it is necessary to synchronize for now.
    watchEffect(() => {
      store.commit('updateSpaceData', { spaces: building.spaces.data, selectedBuilding: building.data?.building });
      store.commit('updateBackgroundInfectionInformation', building.data?.background_infection);
    });

    return {
      buildingId,
      building,
      showAddSpaceModal,
      isEditing,
      spaceToDelete,
      isDeletingSpace,
      userManagementModalOpen,
      isCloningSpace,
      spaceToMove,
      allBuildings,
      buildingToMoveSpace,
      moveSpaceButtonDisabled,
      isMovingSpace
    };
  },
  methods: {
    async goToDetail(space) {
      if (missingAnySpaceFields(space.space)) {
        await this.setSpace(space.space);
        this.showAddSpaceModal = true;
      }
    },
    createRoute(space) {
      if (missingAnySpaceFields(space.space)) {
        return null;
      }
      return { name: 'DashboardSpaceDetailContainer', params: { spaceId: space.id } };
    },
    async reloadData() {
      return Promise.all([
        this.building.mutate(),
        this.building.spaces.mutate(),
      ]);
    },
    async dismissAndReload() {
      await this.setSpace(null);
      this.showAddSpaceModal = false;
      this.isEditing = false;
      await this.reloadData();
    },
    async editSpace(space) {
      this.setSpace(space.space);
      this.isEditing = true;
      this.showAddSpaceModal = true;
    },
    async cloneSpace(space) {
      this.isCloningSpace = true;
      await buildingApiService.cloneSpace({buildingId: this.$route.params.buildingId}, space.id);
      this.isCloningSpace = false;
      await this.reloadData();
    },
    async moveSpace(space) {
      this.spaceToMove = space;
    },
    async showConfirmDeleteSpace(space) {
      this.spaceToDelete = space;
    },
    async confirmDeleteSpace() {
      this.isDeletingSpace = true;
      await this.deleteSpace({
        buildingId: Number(this.$route.params.buildingId),
        spaceId: this.spaceToDelete.id
      });
      this.isDeletingSpace = false;
      this.spaceToDelete = null;
      await this.reloadData();
    },
    async confirmMoveSpace() {
      this.isMovingSpace = true;
      await buildingApiService.moveSpace(this.$route.params.buildingId, this.spaceToMove.id, this.buildingToMoveSpace.id);
      this.isMovingSpace = false;
      this.spaceToMove = null;
      await this.reloadData();
    },
    async cancelDelete() {
      this.spaceToDelete = null;
    },
    async cancelMove() {
      this.spaceToMove = null;
    },
    ...mapActions(['setSpace', 'deleteSpace']),
  }
};
</script>
